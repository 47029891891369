@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .blink_me {
  animation: blinker 3s linear infinite;
  
} */

/* @keyframes blinker {  
  50% { opacity: 0.01; }
} */
.blinking {
  -webkit-animation: blinkingText 2.2s infinite;
          animation: blinkingText 2.2s infinite;
}

@-webkit-keyframes blinkingText {
  /* 0% {
    color: green;
  }
  49% {
    color: red;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #000;
  } */
  50% { opacity: 0.10; }
}

@keyframes blinkingText {
  /* 0% {
    color: green;
  }
  49% {
    color: red;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #000;
  } */
  50% { opacity: 0.10; }
}

/* blink {
  font-size: 20px;
  padding-left: 10px;
  padding-top: 10px;
  display: block;
  opacity: 0.0264508;
} */
:root {
  --blue: #1e3770;
  --light-blue: #3c40c6;
  --white: #ffffff;
  --white-smoke: #efefef;
  --white-smoke-light: #f5f5f5;
  --inner-bg-color: #f6f7fb;
  --error: #ca1f0e;
  --black: #000000;
  --gray: #444444;
  --light-gray: #616161;
  --warning: #fdad2c;
  --success: #5cb85c;
  --dark-gray: #212529;
  --base-font-size: 14px;
  --base-font-family: "Poppins", sans-serif;
  --titles-serif-font: "DM Serif Text", serif;
  --side-panel-header-bg: #d7e2ed;
}


body {
  font-family: "Poppins", sans-serif;
  font-family: var(--base-font-family);
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-size: var(--base-font-size);
  font-weight: 500;
  background: #ffffff;
  overflow-x: hidden;
}
.text-error {
  color: #ca1f0e;
  color: var(--error);
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 1.5px;
}
.HERB-Top-Nav {
  background: #fdfefe;
  color: #000000;
  color: var(--black);
  /* box-shadow: 0 5px 10px rgb(0 0 0 / 10%), 0 15px 10px rgb(0 0 0 / 10%); */
  font-size: 14px;
  font-size: var(--base-font-size);
}
ul li a{text-decoration: none;color:#000000;font-size:14px;}
a{text-decoration: none;}
.HERB-footer {
  background: #efefef;
  background: var(--white-smoke);
  color: #ffffff;
  color: var(--white);
}
.dropdown-item {
  font-size: 14px;
  font-size: var(--base-font-size);
}
.inner-page-container {
  background: #1e3770;
  background: var(--blue);
  min-height: 63px;
  max-height: 63px;
}
.inner-page-content-container {
  background: #ffffff;
  background: var(--white);
  min-height: 650px;
  margin-bottom: 200px;
  padding: 15px;
  border-radius: 5px;
}
.icon-size-header {
  font-size: 22px;
  color: #3c40c6 !important;
  color: var(--light-blue) !important;
  border-bottom: 2px solid #ffffff;
  border-bottom: 2px solid var(--white);
}
.icon-size-header:hover {
  border-bottom: 2px solid #3c40c6;
  border-bottom: 2px solid var(--light-blue);
}
.icon-size-footer {
  font-size: 18px;
  color: #ffffff !important;
  color: var(--white) !important;
}
.footer-text {
  font-size: 14px;
  color: #444444 !important;
  font-weight: 500;
}
.herbpage-service-title h1 {
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  color: var(--white);
  letter-spacing: 1px;
  font-weight: 300;
  font-family: "DM Serif Text", serif;
  font-family: var(--titles-serif-font);
}
.herbpage-service-title h1:before {
  background-color: #ffffff;
  background-color: var(--white);
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 0.1rem;
  width: 42px;
  margin-bottom: 0.25rem;
}
.inner-herbpage-service-title h1 {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  color: var(--white);
  padding-top: 16px !important;
  padding-bottom: 10px;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: "DM Serif Text", serif;
  font-family: var(--titles-serif-font);
}
.inner-herbpage-service-title h1:before {
  background-color: #ffffff;
  background-color: var(--white);
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 0.1rem;
  width: 42px;
  margin-bottom: 0.25rem;
}
.breadcrumb-style {
  color: #ffffff !important;
  color: var(--white) !important;
  font-size: 12px;
  display: flex;
  flex-direction: row-reverse;
  padding-top: 15px;
  padding-right: 15px;
  font-weight: 500;
}
.breadcrumb-style a {
  color: #ffffff;
  color: var(--white);
  font-size: 12px;
  text-align: right !important;
  text-decoration: none;
}
.breadcrumb-style a:hover {
  color: #fdad2c;
  color: var(--warning);
}
.mandatory {
  font-size: 15px;
  color: maroon;
}
/* .input-group-text{min-height: 34px;} */
::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px !important;
}
::placeholder {
  font-size: 14px !important;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  color: var(--dark-gray);
  text-align: center;
  white-space: nowrap;
  background-color: #edf0f3;
  border: 1px solid #ced4da;
  border-radius: 0rem !important;
  color: #444444;
  height: 38px;
}
.label-text-style {
  position: absolute;
  top: -21px;
  font-size: 14px;
  text-transform: capitalize;
}
.mb-4p5 {
  margin-bottom: 32px;
}

/*Model Styleing*/

.modal-header {
  padding: 10px;
  background: #1e3770;
  background: var(--blue);
  color: #ffffff !important;
  color: var(--white) !important;
  height: 60px;
  font-size: 16px !important;
  border-radius: 0px;
  border-bottom: 0px !important;
}
.modal-cap {
  font-size: 16px;
}
.modal-content {
  border-radius: 0px;
  font-size: 16px !important;
}
.modal-footer {
  background: #ffffff;
  background: var(--white);
  height: 60px;
  color: black;
  border-radius: 0px;
  border-top: 0px;
}
.modal-Body {
  border: 0px;
}

/*Stepper*/

.wrapper {
  margin: 0px auto 30px auto;
  background: #fff;
  border-radius: 3px;
}
@media (min-width: 48em) {
  .wrapper {
    max-width: 1500px;
  }
}

.stepper {
  padding: 10px;
  font-size: 13px;
}
@media (min-width: 48em) {
  .stepper {
    padding: 20px;
  }
}
.stepper__list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media (min-width: 48em) {
  .stepper__list {
    display: flex;
    justify-content: space-between;
  }
}
.stepper__list__item {
  padding: 3px 5px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
}
@media (min-width: 48em) {
  .stepper__list__item {
    padding: 10px;
    flex-direction: column;
    flex: 1 1;
  }
}
.stepper__list__item:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
}
@media (min-width: 48em) {
  .stepper__list__item:after {
    width: calc(100% - 100px);
    top: 28%;
    left: calc(50% + 50px);
    border-top: 2px dotted #e2dfda;
  }
}
.stepper__list__item--done {
  color: #178a00;
  transition: all 0.1s;
}
@media (min-width: 48em) {
  .stepper__list__item--done:after {
    border-top-style: solid;
    border-top-width: 1px;
  }
}
.stepper__list__item--done:hover,
.stepper__list__item--done:focus {
  text-decoration: underline;
  cursor: pointer;
}
.stepper__list__item--current {
  color: #3c40c6;
  color: var(--light-blue);
}
.stepper__list__item--current:last-of-type:after,
.stepper__list__item--current:only-of-type:after {
  height: 30%;
}
.stepper__list__item:last-of-type:after {
  display: none;
}
.stepper__list__item--pending {
  color: #807370;
}
.stepper__list__item--pending:after {
  height: 30%;
}
.stepper__list__title {
  margin: 1px 0 0;
}
@media (min-width: 48em) {
  .stepper__list__title {
    margin: 0;
  }
}
.stepper__list__icon {
  margin: 0 10px 0 0;
  height: 2em;
  width: 2em;
}
@media (min-width: 48em) {
  .stepper__list__icon {
    margin: 0 0 15px;
  }
}
.stepper__list__icon path {
  fill: currentColor;
}
.stepper__list__icon ellipse,
.stepper__list__icon circle {
  stroke: currentColor;
}

/*Button*/
.btn {
  border-radius: 0px;
}
.stepicon {
  font-size: 30px;
}

/*jumbotron*/
.jumbotron {
  background: #f7f7f7;
  margin-top: -20px;
  line-height: 1.3;
  border: 1px solid #ddd;
}
.jumbotron span {
  display: block;
  margin-bottom: -10px;
  color: #1e3770;
  color: var(--blue);
}
.button-spacing {
  margin-right: 5px;
}

.typeahead-list-group {
  height: 220px !important;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 35px;
  width: 100%;
}
.inner-page-margin {
  width: 100%;
  margin: 3%;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(17 86 157) !important;
  border-left: 1px solid #dbd5d5;
  
}
.navbar-light .navbar-nav .nav-link:nth-child(1){
  border-left: 0px solid #dbd5d5!important;

}

.navbar-light .navbar-nav .nav-link:hover {
  text-decoration: underline;
  
}
/*Side Panel Style*/
.innerpage-sidepanel {
  background: #f5f5f5;
  background: var(--white-smoke-light);
  min-height: 768px;
  height: 95vh;
  overflow: auto;
  margin-top: 0px;
  border-right: 1px solid #dddddd;
  padding-bottom: 520px;
  overflow-x: hidden;
}
.innerpage-sidepanel-header {
  height: 170px;
  background: #d7e2ed;
  background: var(--side-panel-header-bg);
  padding: 10px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 30;
}

.innerpage-sidepanel-body {
  margin: 10px;
  background: #ffffff;
  min-height: 600px;
  height: 100vh;
  padding-bottom: 100px;
}
.innerpage-sidepanel-header-title {
  font-size: 1.4rem;
  color: #1e3770;
  color: var(--blue);
  text-align: left;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.innerpage-sidepanel-header-title-2 {
  font-size: 1rem;
  color: #1e3770;
  color: var(--blue);
  text-align: left;
  font-weight: 400;
  margin-top: -3px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.innerpage-container {
  margin-top: 10px;
}
.special-button {
  font-size: 25px;
  cursor: pointer;
}
.special-button-small {
  font-size: 22px;
}
.btn-group-sm {
  border-top: 0.5px solid #efefef;
  border-top: 0.5px solid var(--white-smoke);
  border-bottom: 0.5px solid #efefef;
  border-bottom: 0.5px solid var(--white-smoke);
}
.btn.btn-outline-secondary {
  font-size: 14px !important;
  color: #212529;
  color: var(--dark-gray);
  border: 0px;
  z-index: 1;
}
.btn.btn-outline-secondary.focus,
.btn.btn-outline-secondary:focus {
  color: #fff;
  background-color: #5cb85c;
  background-color: var(--success);
  border-color: #5cb85c;
  border-color: var(--success);
  outline: none;
  box-shadow: none;
}
.btn.btn-outline-secondary:hover {
  color: #fff;
  background-color: #5cb85c;
  background-color: var(--success);
  border-color: #5cb85c;
  border-color: var(--success);
  outline: none;
  box-shadow: none;
}
.btn.btn-outline-secondary.active,
.btn.btn-outline-secondary:active {
  color: #fff;
  background-color: #5cb85c;
  background-color: var(--success);
  border-color: #5cb85c;
  border-color: var(--success);
  outline: none;
}
.btn.btn-outline-secondary.active.focus,
.btn.btn-outline-secondary.active:focus,
.btn.btn-outline-secondary.active:hover,
.btn.btn-outline-secondary:active.focus,
.btn.btn-outline-secondary:active:focus,
.btn.btn-outline-secondary:active:hover {
  color: #fff;
  background-color: #5cb85c;
  background-color: var(--success);
  border-color: #5cb85c;
  border-color: var(--success);
  outline: none;
  box-shadow: none;
}
.side-panel-list-container {
  display: flex;
  align-items: flex-start;
  height: 120px;
  background: #ffffff;
  background: var(--white);
  border-bottom: 1px solid #efefef;
  border-bottom: 1px solid var(--white-smoke);
  padding: 10px;
}
.side-panel-list-container:hover {
  border-left: 4px solid #5cb85c;
  border-left: 4px solid var(--success);
  background: #f6fbff;
  transition: 0.3s;
}
.listactive {
  border-left: 4px solid #5cb85c;
  border-left: 4px solid var(--success);
  background: #f6fbff;
  border-top: 0.5px solid #c3d8e7;
  border-right: 0.5px solid #c3d8e7;
  border-bottom: 0.5px solid #c3d8e7;
}
.side-panel-list-container-left {
  width: 64%;
  float: left;
  text-align: left;
}
.side-panel-list-container-left .description {
  font-size: 15px;
  color: #444444;
  color: var(--gray);
  line-height: 1.1;
  font-weight: 500;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.side-panel-list-container-left .code {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #616161;
  color: var(--light-gray);
}
.side-panel-list-container-left .time {
  padding-top: 10px;
  font-size: 12px;
  line-height: 1.1;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #616161;
  color: var(--light-gray);
}
.side-panel-list-container-left .version {
  padding-top: 3px;
  font-size: 12px;
  line-height: 1.1;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #616161;
  color: var(--light-gray);
}
.side-panel-list-container-right {
  width: 35%;
  float: right;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}
.side-panel-list-container-right .value {
  font-size: 24px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #616161;
  color: var(--light-gray);
}
.side-panel-list-container-right .value small {
  font-size: 10px;
  font-weight: 600;
}
.side-panel-list-container-right .inr {
  font-size: 16px;
  font-weight: 600;
  margin-top: -10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #616161;
  color: var(--light-gray);
}
.side-panel-list-container-right .status-success {
  background: #5cb85c;
  background: var(--success);
  color: #ffffff;
  font-size: 10px;
  padding: 3px 8px;
  border-radius: 15px;
}
.side-panel-list-container-right .status-warning {
  background: #fdad2c;
  background: var(--warning);
  color: #ffffff;
  font-size: 10px;
  padding: 3px 8px;
  border-radius: 15px;
}
.side-panel-list-container-right .status-danger {
  background: #ca1f0e;
  background: var(--error);
  color: #ffffff;
  font-size: 10px;
  padding: 3px 8px;
  border-radius: 15px;
}
.side-panel-list-container-right .status-primary {
  background: #1e3770;
  background: var(--blue);
  color: #ffffff;
  font-size: 10px;
  padding: 3px 8px;
  border-radius: 15px;
}

/*Main content Head Style*/
.main-content-head {
  background: #d7e2ed;
  background: var(--side-panel-header-bg);
  min-height: 170px;
  padding: 20px;
}
.main-content-head-left-heading {
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
}
.main-content-head-right {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
}
.child-desc {
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid #efefef;
  border-top: 0.5px solid var(--white-smoke);
  border-bottom: 0.5px solid #efefef;
  border-bottom: 0.5px solid var(--white-smoke);
  padding: 10px 5px;
}
.child-desc .title {
  display: block;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
}
.child-desc .titledesc {
  display: block;
  text-align: left;
  font-size: 15px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1.2;
  text-transform: capitalize;
}
.responsive-navbar-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: #f5f5f5 !important;
  background-color: var(--white-smoke-light) !important;
  /* text-transform: uppercase !important; */
  color: #67707c;
}
/*Tabs*/
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  /* text-transform: uppercase !important; */
  color: #444444;
  color: var(--gray);
  border-right: 1px solid #f5f5f5;
  border-right: 1px solid var(--white-smoke-light);
  width: auto;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  color: var(--white) !important;
  background-color: #1e3770 !important;
  background-color: var(--blue) !important;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 0px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #212529 !important;
  color: var(--dark-gray) !important;
  /* text-transform: uppercase !important; */
  font-size: 13px !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}



/* Login Page css starts */
.left_bg {
  background-color: #ffffff;
  /* height: 100vh;*/
}
.right_bg {
  background-color: #d5e4fd;
  height: 100vh;
  overflow-y: auto;
  /* display: table;*/
}
.vertical-center {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.main_form {
  display: flex;
  flex-direction: column;
 
  justify-content: center;
  align-items: center;
}
/* .form_adjust {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
} */
.main-title {
  font-size: 26px;
  line-height: 30px;
  color: #1e3770;
  font-family: "DM Serif Text", serif;
  font-family: var(--titles-serif-font);
  padding-bottom: 20px;
  letter-spacing:1px;
}
.aplogo {
  margin-top: 50px;
}
.font_size {
  font-size: 24px;
}
.forgot {
  color: #6a6a6a;
  text-decoration: none;
}
.forgot:hover {
  color: #6a6a6a;
  text-decoration: none;
  cursor: pointer;
}
.group_txt {
  background-color: #edf1fa;
  /* border: 1px solid #329653; */
  color: #212529;
  border: 1px solid #b9c6e3;
}
.form-control {
  background: #edf1fa;
  border: 1px solid #b9c6e3;
  border-radius: 0px;
}
.btn_primary {
  background-color: #1e3770;
  border-color: #1e3770;
  /* z-index: 9999; */
}
.btn_primary:hover {
  background-color: #1e3770;
  border-color: #1e3770;
}

/* Login Page css starts */
.main_section {
  background-image: linear-gradient(to top, #ffffff, #c1cff6);
  min-height: 230px;
  position: fixed;
  width: 600px;
  height: 300px;
  left: 0%;
  top: 5%;
  width: 100%;
  z-index: -1;
}
.breadstyle li a {
  color: #1e3770;
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #fdad2c !important;
  color: var(--warning) !important;
  font-weight: bold;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #efefef !important;
  color: var(--white-smoke) !important;
  content: "/";
}
.useritem {
  color: #1e3770 !important;
}
.useritem span {
  color: #1e3770 !important;
  font-weight: bold;
}
.box_item {
  min-height: 150px;
  background-color: #ffffff;
}
.card {
  border-radius: 10px;
  border: 0px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px -4px 28px rgb(0 0 0 / 10%), 0px 10px 10px rgb(0 0 0 / 10%);
  margin-top: 20px;
}
.card_title {
  color: #5f5f5f;
  font-size: 14px;
  text-align: left;
  height: 34px;
  width: 110px;
  text-transform: capitalize !important;
}
.unchecked {
  color: #cfcfcf;
}
.head_bottom1 {
  border-top: 5px solid #ffaebc;
  width: 60%;
}
.head_bottom2 {
  border-top: 5px solid #a0e7e5;
  width: 60%;
}
.head_bottom3 {
  border-top: 5px solid #b4f8c8;
  width: 60%;
}
.head_bottom4 {
  border-top: 5px solid #fbe7c6;
  width: 60%;
}
.head_bottom5 {
  border-top: 5px solid #b4def8;
  width: 60%;
}
.head_bottom6 {
  border-top: 5px solid #fbc6df;
  width: 60%;
}
.inner-herbpage-service-title2 h1 {
  font-size: 26px;
  font-weight: 500;
  text-align: left;
  color: #1e3770;
  padding-top: 40px !important;
  padding-bottom: 10px;
  font-family: "DM Serif Text", serif;
  font-family: var(--titles-serif-font);
}
.inner-herbpage-service-title2 h1:before {
  background-color: #a0e7e5;
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 0.2rem;
  width: 42px;
  margin-bottom: 0.25rem;
}
.checked {
  color: orange;
}
.container-fluid {
  padding: 0px;
}
.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
  z-index: 999999;

  
}
.fp-container .fp-loader {
  top: 45%;
  left: 48%;
  z-index: 1000;
  position: absolute;
  width: 80px;
  height: 80px;
  
}

.tab-cap{line-height:1;font-size: 14px;font-weight: 400;letter-spacing: 0.5px;font-weight: 500;}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .nav-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #dddddd;
  }
  .dots{
   display: none;
  }
  .corner{
    display: none;
  }
  .form_adjust {
    display: table-cell;
    vertical-align: middle;
    width: 80%;
}
}

@media only screen and (min-width: 769px) {
  .dots{
    position: absolute;
    left:1%;
    top: 5%;
    width: 250px;
  }
  .corner{
    position: absolute;
    right: 0;
    bottom:0px;
    width: 375px;
    z-index: 1;
  }
  .form_adjust {
    display: table-cell;
    vertical-align: middle;
    width: 80%;
}
 
.react-tabs{
  background: #ffffff;
  padding:3px;
}
/*React Tabs*/
.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  min-width: 80px;
  text-align: center;
  background: #ffffff;
  background: var(--white);
}

.react-tabs__tab-list {
  border-bottom: 1px solid #f5f5f5 !important;
  border-bottom: 1px solid var(--white-smoke-light) !important;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  /* display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer; */
  font-size: 12px;
  border-bottom: 1px solid #ddd !important;
  text-transform: capitalize !important;
  color: #444444 !important;
  color: var(--gray) !important;
  border: 1px solid #f5f5f5 !important;
  border: 1px solid var(--white-smoke-light) !important;
  width: 150px !important;
}

.react-tabs__tab--selected {
  color: #ffffff !important;
  color: var(--white) !important;
  /* background-color: var(--blue) !important; */
  background: #EBF1FF;
  background: linear-gradient(to bottom, #EBF1FF, #2464C6)  !important;
  border-color: #616161;
  border-color: var(--light-gray);
  border-radius: 0px !important;
}

.tabicon {
  display: block;
  text-align: center;
  margin: 0 auto;
  font-size: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.tabiconc{
  width:70px !important;
  height:70px !important;
  display:block;
  margin: 0 auto;
  padding-bottom: 10px;
  padding-top: 10px;
  }
.btnicon {
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
}

.btnicon-textwhite {
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
  color: #ffffff;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .nav-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #dddddd;
  }
  /*React Tabs*/
.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  min-width: 80px;
  text-align: center;
  background: #ffffff;
  background: var(--white);
}

.react-tabs__tab-list {
  border-bottom: 1px solid #f5f5f5 !important;
  border-bottom: 1px solid var(--white-smoke-light) !important;
  margin: 0 0 10px;
  padding: 0;
}
.tabiconc{
  width:60px !important;
  height:60px !important;
  display:block;
  margin: 0 auto;
  }
.react-tabs__tab {
  /* display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer; */
  font-size: 12px;
  border-bottom: 1px solid #ddd !important;
  text-transform: uppercase !important;
  color: #444444 !important;
  color: var(--gray) !important;
  border: 1px solid #f5f5f5 !important;
  border: 1px solid var(--white-smoke-light) !important;
  width: 100% !important;
}

.react-tabs__tab--selected {
  color: #ffffff !important;
  color: var(--white) !important;
  background-color: #1e3770 !important;
  background-color: var(--blue) !important;
  border-color: #616161;
  border-color: var(--light-gray);
  border-radius: 0px !important;
}

.tabicon {
  display: block;
  text-align: center;
  margin: 0 auto;
  font-size: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.btnicon {
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
}
.btnicon-textwhite {
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
  color: #ffffff;
}
}


.inner-herbpage-service-nodual-header-title1 h1 {
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  color: #1e3770;
  color: var(--blue);
  padding-top: 5px !important;
  padding-bottom: 10px;
  font-family: "DM Serif Text", serif;
  font-family: var(--titles-serif-font);
}

.inner-herbpage-service-nodual-header-title1 h1:before {
  background-color: #fdad2c;
  background-color: var(--warning);
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 0.2rem;
  width: 42px;
  margin-bottom: 0.25rem;
}

.inner-herbpage-service-title1 h1 {
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  color: #1e3770;
  color: var(--blue);
  padding-top: 15px !important;
  padding-bottom: 10px;
  font-family: "DM Serif Text", serif;
  font-family: var(--titles-serif-font);
}

.inner-herbpage-service-title1 h1:before {
  background-color: #fdad2c;
  background-color: var(--warning);
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 0.2rem;
  width: 42px;
  margin-bottom: 0.25rem;
}

.inner-herbpage-service-title-sub h1 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--info);
  padding-top: 40px !important;
  padding-bottom: 10px;
  font-family: "DM Serif Text", serif;
  font-family: var(--titles-serif-font);
}

.inner-herbpage-service-title-sub h1:before {
  background-color: #5cb85c;
  background-color: var(--success);
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 0.2rem;
  width: 42px;
  margin-bottom: 0.25rem;
}

.inner-herbpage-service-title-sub-np h1 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--info);
  padding-top: 0px !important;
  padding-bottom: 10px;
  font-family: "DM Serif Text", serif;
  font-family: var(--titles-serif-font);
}

.inner-herbpage-service-title-sub-np h1:before {
  background-color: #5cb85c;
  background-color: var(--success);
  border-radius: 0.25rem;
  content: "";
  display: block;
  height: 0.2rem;
  width: 42px;
  margin-bottom: 0.25rem;
}

.anchortagset{
  cursor: pointer;color:var(--info)
}

.cards {
  border-radius: 10px;
  border: 0px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px -4px 28px rgb(0 0 0 / 5%), 0px 10px 10px rgb(0 0 0 / 5%);
  /* margin-top: 20px; */
  background-image: linear-gradient(to left top, #e7f3f8, #e8f4f9, #e8f5fb, #e9f7fc, #e9f8fd) !important;
  background-clip: border-box;
  border: 1px solid #d2ebf4 !important;
  border-radius: 0.25rem;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%), 0 15px 10px rgb(166 173 201 / 10%);
}
 
.cards-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  min-height: 75px;
  position: relative;
}
.cards-body h5 {
  color: #000000 ;
  font-size: 14.5px !important;
  text-align: left;
  line-height: 1.2;
  width: 90%;
  font-weight: 500;
}

.icon-size-header2{ 
  color: #3c40c6 !important; 
  color: var(--light-blue) !important; 
  font-size: 18px !important;
  margin-top: -4px;
}
.cardico{
  position: absolute;
  bottom: 10%;
  right: 7%;
  color: #ffffff !important;
  font-size:20px !important;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 38px;
  height: 38px;
  overflow: hidden;
  bottom: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 42px 0px 4px 0px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  max-width: 100%;
  background-color: #f2f8f9;
  border-radius: 4px; 
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.card1:hover:before {
  -webkit-transform: scale(21);
          transform: scale(21);
}

.card1:hover a {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card1:hover h5 {
  transition: all 0.3s ease-out;
  color: #ffffff;
  font-weight: 500;
}
.footer {
  background: #efefef;
  background: var(--white-smoke);
  padding: 10px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: left;
}
.header-dropdown-setting{
  min-width:150px;
  font-size:60px;
  /* text-align:center; */
  background:#e5ebff;
  border-radius: 15px;
  padding-right: 10px; 
} 

.android{
  background: #669933 !important;
  color: #ffffff;
  padding: 10px 10px;text-decoration: none;
  margin-bottom: 10px;
}
.android a,.ios a{text-decoration: none !important;}
.ios{
  background: #666666 !important;
  color: #ffffff;
  padding: 10px 10px;text-decoration: none;
  margin-bottom: 10px;
}

.outer-page-content-container{
  background: #ffffff;
  background: var(--white);
  min-height: 650px; 
  box-shadow: 0 5px 10px rgb(0 0 0  / 5%), 0 15px 10px rgb(0 0 0  / 5%); 
  margin-bottom: 100px;
  padding: 15px;
  border-radius: 5px; 
  margin-top: -50px !important;
}
.outer-page-content-container-landing{
  background: #ffffff;
  background: var(--white);
  min-height: 650px; 
  box-shadow: 0 5px 10px rgb(0 0 0  / 5%), 0 15px 10px rgb(0 0 0  / 5%); 
  margin-bottom: 100px;
  padding: 15px;
  border-radius: 5px; 
  margin-top: 80px !important;
}
.outer-page-content-container-nodual-header{
  background: #ffffff;
  background: var(--white);
  min-height: 650px; 
  box-shadow: 0 5px 10px rgb(0 0 0  / 5%), 0 15px 10px rgb(0 0 0  / 5%); 
  margin-bottom: 100px;
  padding: 15px;
  border-radius: 5px; 
  margin-top: -65px;
}
.text-dark{
  color:#000000 !important;
}
.landing-icon-for-demo{
  color: #5cb85c;
  color: var(--success);
  font-size: 35px;
  padding-right: 10px;
}
.landing-icon-for-demo-list{
 color: #5cb85c;
 color: var(--success);
 font-size:30px;
 padding-right: 10px;
}

.login-title{
  border-left: 4px solid rgb(61, 133, 94);
  padding-left:15px;
  color:#5f5f5f;
}



#main {
  margin: 50px 0;
}

#main #faq .card {
  margin-bottom: 30px;
  border: 0;
}

#main #faq .card .card-header {
  border: 0;
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #FFE472;
  color: #222;
  padding: 20px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #A541BB;
  color: #fff;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapsing {
  background: #FFE472;
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #FFE472;
  line-height: 30px;
  color: #222;
}
